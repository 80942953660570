import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import RichText from "../components/rich-text"
import Carousel from "../components/carousel"
import NewsListing from "../components/news-listing"
import { Arrow } from "../components/icons"
import { mq, site } from "../styles/variables"

const Intro = styled.section`
  @media ${mq.gte("l")} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: ${site.gridGutter}px;

    h1 {
      grid-column: span 6;
      padding: 0 20px 40px 0;
    }
  }
`

const Text = styled.div`
  @media ${mq.gte("l")} {
    grid-column: span 6;
    padding-top: 24px;
  }
`

const Section = styled.section`
  margin: 110px 0;

  @media ${mq.gte("l")} {
    margin-top: 250px;
    margin-bottom: 210px;
  }
`

const LargeLink = styled.p`
  margin: 0;
  font-size: 32px;

  a {
    box-shadow: none;
    display: inline-flex;
    align-items: center;
  }

  svg {
    width: 30px;
    height: 25px;
    margin: 3px 12px 0 0;
    transition: all 250ms 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    transform: translate(0, 0);
  }

  &.reveal:not([style]) svg {
    opacity: 0;
    transform: translate(-20px, 0);
  }

  @media ${mq.gte("l")} {
    margin-top: -70px;
  }
`

const Start = ({
  location,
  data: {
    sanityStart: { description, title, _rawRichtext, stories },
    allSanityNews: { nodes: news },
  },
}) => {
  const bgColor = "#fff"

  return (
    <Layout
      color={bgColor}
      location={location}
      description={description}
      title={`Alliance Venture Capital – ${title}`}
    >
      <Carousel items={stories} />

      <Intro>
        <h1 className="reveal">{title}</h1>

        <Text>
          {_rawRichtext && (
            <RichText blocks={_rawRichtext} className="reveal" />
          )}
        </Text>
      </Intro>

      <LargeLink className="reveal">
        <Link to="/team">
          <Arrow /> <span>Meet the team</span>
        </Link>
      </LargeLink>

      <Section>
        <NewsListing newsItems={news} />
      </Section>
    </Layout>
  )
}

export default Start

export const query = graphql`
  query StartPageQuery {
    sanityStart {
      description
      title
      _rawRichtext
      stories {
        _key
        image {
          asset {
            id
            fluid {
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
          }
        }
        company
        prioritized
        disabled
        quote
        imgPosition
        bottomGradient
        textColor
        wideText
        pageColor
        textX
        textY
        cite
        anchorRight
      }
    }
    allSanityNews(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        link
        date(formatString: "D MMMM YYYY")
        _rawRichtext
      }
    }
  }
`
