import React, { useEffect, useState, useRef } from "react"
import Img from "gatsby-image"
import styled, { keyframes } from "styled-components"
import gsap from "gsap"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { mq } from "../styles/variables"

const FadeTransition = (props) => (
  <CSSTransition
    {...props}
    classNames={{
      enter: `fade-enter`,
      enterActive: `fade-enter-active`,
      exit: `fade-exit`,
    }}
    addEndListener={(node, done) => {
      node.addEventListener("transitionend", done, false)
    }}
  />
)

const fadeInUp = keyframes`
  from { opacity: 0; transform: translate(0, 15px); }
  to { opacity: 1; transform: translate(0, 0); }
`

const Wrapper = styled.div`
  margin-top: -50px;
  margin-bottom: 100px;

  @media ${mq.gte("m")} {
    margin-top: -75px;
    margin-bottom: 155px;
  }

  @media ${mq.gte("l")} {
    margin-top: -105px;
  }
`

const Image = styled.div`
  background: rgba(255, 255, 255, 0.5);
  height: 388px;
  margin: 0 0 10px;
  position: relative;

  @media ${mq.gte("m")} {
    height: 500px;
    margin-left: -5.42vw;
    margin-right: -5.42vw;
  }

  @media ${mq.gte("l")} {
    height: auto;
    padding-top: 68%;
  }

  @media (min-width: 1440px) {
    margin-left: -78px;
    margin-right: -78px;
  }

  ${({ $shadow }) =>
    $shadow &&
    `
      @media ${mq.gte("l")} {
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.68) 0%, rgba(43, 32, 14, 0.00) 43.61%);
        }
      }
    `}
`

const StyledImg = styled(Img)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 500ms;
`

const Indicators = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const Index = styled.div`
  font-size: 32px;
  width: 2.2em;
  line-height: 1;

  @media ${mq.gte("l")} {
    font-size: 56px;
  }
`

const ProgressHolder = styled.div`
  flex-grow: 1;
`

const Progress = styled.div`
  background: rgba(0, 0, 0, 0.2);
`

const Track = styled.div`
  height: 1px;
  background: rgba(0, 0, 0, 1);
  transform: scale(0, 1);
  transform-origin: 0 50%;
`

const Titles = styled.div`
  font-size: 12px;
  margin: 4px 0 2px;
  display: flex;
  justify-content: space-between;
`

const CurrentIndex = styled.div`
  display: inline-block;
  width: 0.59em;
  text-align: right;
`

const Top = styled.div`
  @media ${mq.gte("m")} {
    margin-left: 5.42vw;
    margin-right: 5.42vw;
  }

  @media ${mq.gte("l")} {
    position: relative;
  }

  @media (min-width: 1440px) {
    margin-left: 78px;
    margin-right: 78px;
  }
`

const Text = styled.div`
  position: relative;
  min-height: 165px;
  opacity: 0.7;
  max-width: 500px;

  @media ${mq.gte("m")} {
    min-height: 210px;
    max-width: 600px;
  }

  @media ${mq.gte("l")} {
    position: static;
    height: 0;
    min-height: 0;
    opacity: 0.9;
  }

  @media (min-width: 1440px) {
    max-width: 600px;
  }
`

const Quote = styled.blockquote`
  position: absolute;
  top: 10px;
  width: 100%;
  margin: 0;
  z-index: 1;
  opacity: 0;

  cite {
    font-size: 16px;
    font-style: normal;
  }

  &.active {
    animation: ${fadeInUp} 400ms 200ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }

  @media ${mq.lt("l")} {
    top: 10px !important;
    right: auto !important;
    bottom: auto !important;
    color: #000 !important;
  }

  @media ${mq.gte("m")} and ${mq.lt("l")} {
    left: -5.42vw !important;
  }

  @media ${mq.gte("l")} {
    top: auto;
    width: 41.67vw;

    cite {
      font-size: 26px;
    }

    ${({ $wideText }) =>
      $wideText &&
      `
        width: 45vw;

    `}
  }

  @media (min-width: 1440px) {
    width: 618px;

    ${({ $wideText }) =>
      $wideText &&
      `
        width: 750px;

    `}
  }
`

const P = styled.p`
  margin: 0 0 10px;
  line-height: 1;
  letter-spacing: -0.02em;

  &::before {
    content: "“";
  }

  &::after {
    content: "”";
  }

  ${({ size }) =>
    size === "small" &&
    `
    font-size: 24px;
  `}

  ${({ size }) =>
    size === "large" &&
    `
    font-size: 30px;
  `}


  @media ${mq.gte("m")} {
    ${({ size }) =>
      size === "small" &&
      `
      font-size: 30px;
    `}

    ${({ size }) =>
      size === "large" &&
      `
      font-size: 45px;
    `}
  }

  @media ${mq.gte("l")} {
    ${({ size }) =>
      size === "small" &&
      `
      font-size: 3.61vw;
    `}

    ${({ size }) =>
      size === "large" &&
      `
      font-size: 4.17vw;
    `}
  }

  @media ${mq.gte("l")} {
    margin: 0 0 20px;
  }

  @media (min-width: 1440px) {
    ${({ size }) =>
      size === "small" &&
      `
      font-size: 52px;
    `}

    ${({ size }) =>
      size === "large" &&
      `
      font-size: 60px;
    `}
  }
`

const Carousel = ({ items }) => {
  const [index, setIndex] = useState(0)
  const [sortedItems, setSortedItems] = useState([])

  const track = useRef()

  useEffect(() => {
    setSortedItems(
      items
        .filter(({ disabled }) => !disabled)
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
        .sort((a) => (a.prioritized ? -1 : 0))
    )
  }, [])

  useEffect(() => {
    if (sortedItems.length > 0) {
      const timeline = gsap.timeline()

      requestAnimationFrame(() =>
        requestAnimationFrame(() => {
          document.body.style.background = sortedItems[index].pageColor
        })
      )

      timeline
        .fromTo(
          track.current,
          {
            transform: "scale(0, 1)",
          },
          {
            transform: "scale(1, 1)",
            ease: "linear",
            duration: 6,
          }
        )
        .call(() => {
          const newIndex = index === sortedItems.length - 1 ? 0 : index + 1
          setIndex(newIndex)
        })

      return () => {
        timeline.kill()
      }
    }
  }, [index, sortedItems])

  return (
    <Wrapper>
      <Top>
        <Image
          $shadow={sortedItems[index] && sortedItems[index].bottomGradient}
        >
          {sortedItems.map(({ image }, i) => {
            image.asset.fluid.sizes = "(min-width: 1440px) 1320px, 100vw"

            return (
              <StyledImg
                imgStyle={{ objectPosition: sortedItems[i].imgPosition }}
                key={image.asset.id}
                fluid={image.asset.fluid}
                style={{ opacity: index === i ? 1 : 0 }}
              />
            )
          })}
        </Image>

        <Text>
          {sortedItems.map(
            (
              { quote, cite, textColor, wideText, textX, textY, anchorRight },
              i
            ) => (
              <Quote
                key={i}
                className={index === i ? "active" : ""}
                style={{
                  color: textColor,
                  [anchorRight ? "right" : "left"]: textX,
                  bottom: textY,
                }}
                $wideText={wideText}
              >
                <P size={quote.length > 90 ? "small" : "large"}>{quote}</P>
                <cite>
                  {}
                  {cite}
                </cite>
              </Quote>
            )
          )}
        </Text>
      </Top>

      <Indicators>
        <Index>
          {sortedItems.length > 0 ? (
            <>
              <SwitchTransition mode="out-in">
                <FadeTransition key={index}>
                  <CurrentIndex>{index + 1}</CurrentIndex>
                </FadeTransition>
              </SwitchTransition>
              /{sortedItems.length}
            </>
          ) : (
            <CurrentIndex />
          )}
        </Index>
        <ProgressHolder>
          <Progress>
            <Track ref={track} />
          </Progress>
          <Titles>
            {sortedItems.length > 0 && (
              <>
                <SwitchTransition mode="out-in">
                  <FadeTransition key={sortedItems[index].company}>
                    <div style={{ transitionDelay: "70ms" }}>
                      {sortedItems[index].company}
                    </div>
                  </FadeTransition>
                </SwitchTransition>
                <SwitchTransition mode="out-in">
                  <FadeTransition
                    key={
                      sortedItems[
                        index === sortedItems.length - 1 ? 0 : index + 1
                      ].company
                    }
                  >
                    <div style={{ transitionDelay: "140ms" }}>
                      {
                        sortedItems[
                          index === sortedItems.length - 1 ? 0 : index + 1
                        ].company
                      }
                    </div>
                  </FadeTransition>
                </SwitchTransition>
              </>
            )}
          </Titles>
        </ProgressHolder>
      </Indicators>
    </Wrapper>
  )
}

export default Carousel
