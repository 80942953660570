import React, { useState } from "react"
import styled from "styled-components"
import RichText from "../components/rich-text"
import { Arrow } from "../components/icons"
import { mq } from "../styles/variables"

const Heading = styled.h2`
  font-size: 60px;
  margin-bottom: 0.42em;

  @media ${mq.gte("l")} {
    font-size: 80px;
  }
`

const Date = styled.div`
  font-size: 14px;
  margin: 0 0 0.7em;
`

const NewsItems = styled.div`
  font-size: 16px;
  position: relative;

  @media ${mq.gte("m")} {
    white-space: nowrap;
    overflow: hidden;
    margin: 0 -40px;
  }

  @media ${mq.gte("l")} {
    margin: 0;
  }
`

const NewsItemsHolder = styled.div`
  @media ${mq.lt("m")} {
    transform: translate(0, 0) !important;
  }

  @media ${mq.gte("m")} {
    transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`

const NewsItem = styled.div`
  margin-bottom: 50px;

  @media ${mq.lt("m")} {
    max-width: 500px;

    &:nth-child(n + 3):not(.show) {
      display: none;
    }
  }

  @media ${mq.gte("m")} {
    white-space: normal;
    width: 50%;
    display: inline-block;
    padding: 0 40px;
    vertical-align: top;
  }

  @media ${mq.gte("l")} {
    padding: 0 120px 0 0;
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 20px;
  bottom: -40px;

  @media ${mq.lt("m")} {
    //display: none;
  }

  @media ${mq.gte("m")} {
    width: 134px;
    bottom: 15px;
  }

  @media ${mq.gte("l")} {
    right: -20px;
  }
`

const MoreNews = styled.button`
  appearance: none;
  padding: 0 0 4px;
  margin: 0;
  border: 0;
  background: transparent;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  color: inherit;

  @media ${mq.lt("m")} {
    transition: box-shadow 200ms;

    &:hover {
      box-shadow: 0 2px rgba(0, 0, 0, 1);
    }

    &.hide {
      display: none;
    }
  }

  @media ${mq.gte("m")} {
    position: absolute;
    font-size: 22px;
    top: 13px;
    right: 70px;
    padding: 2px 0 0 0;

    &.hide-m {
      opacity: 0;
      pointer-events: none;
    }
  }
`

const Prev = styled.button`
  appearance: none;
  padding: 20px 17px 20px 20px;
  margin: 0;
  border: 0;
  background: transparent;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);

  svg {
    width: 30px;
    height: 25px;
    transform: rotate(180deg);
  }

  &[disabled] {
    cursor: default;
    opacity: 0;
  }

  @media ${mq.lt("m")} {
    display: none;
  }
`

const Next = styled.button`
  appearance: none;
  padding: 20px 20px 20px 17px;
  margin: 0;
  border: 0;
  background: transparent;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);

  svg {
    width: 30px;
    height: 25px;
  }

  &[disabled] {
    cursor: default;
    opacity: 0;
  }

  @media ${mq.lt("m")} {
    display: none;
  }
`

const SmallArrow = styled(Arrow)`
  width: 15px;
  margin-left: 4px;
  transform: rotate(90deg);
  position: relative;
  top: -1px;

  @media ${mq.gte("m")} {
    display: none;
  }
`

const NewsListing = ({ newsItems }) => {
  const [index, setIndex] = useState(0)
  const [showAllNews, setShowAllNews] = useState(false)

  return (
    <div>
      <Heading className="reveal">The latest</Heading>

      <NewsItems>
        <NewsItemsHolder
          style={{ transform: `translate(-${index * 50}%, 0%)` }}
        >
          {newsItems.map(({ id, title, link, date, _rawRichtext }) => (
            <NewsItem
              key={id}
              className={`reveal ${showAllNews ? "show" : ""}`}
            >
              <Date>{date}</Date>
              <h3>
                <a href={link}>{title}</a>
              </h3>
              {_rawRichtext && <RichText blocks={_rawRichtext} />}
            </NewsItem>
          ))}
        </NewsItemsHolder>
        {newsItems.length > 2 && (
          <Controls className="reveal">
            <Prev
              disabled={index === 0}
              onClick={() => {
                setIndex(Math.max(0, index - 1))
              }}
            >
              <Arrow />
            </Prev>
            <MoreNews
              className={`
                ${showAllNews ? "hide" : ""}
                ${index > 0 ? "hide-m" : ""}
              `}
              onClick={() => {
                setShowAllNews(true)
                setIndex(index + 1)
              }}
            >
              More news
              <SmallArrow />
            </MoreNews>
            <Next
              disabled={index === newsItems.length - 2}
              onClick={() => {
                setIndex(Math.min(Math.floor(newsItems.length / 2), index + 1))
              }}
            >
              <Arrow />
            </Next>
          </Controls>
        )}
      </NewsItems>
    </div>
  )
}

export default NewsListing
